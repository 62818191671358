
export function getLocaleNumber(number: number, currency=false, min=0, max=10) {
    if(!number || number===undefined) return 'n.a.';
    return `${currency ? '$' : ''}${number.toLocaleString(undefined, {
        minimumFractionDigits: min,
        maximumFractionDigits: max
    })}`;
}

const siPrefixes: readonly string[] = ['', 'k', 'M', 'B', 'T'];

export function getPrefixedNumber(number: number, currency=false, min=0, max=10) {
    if(!number || number===undefined) return 'n.a.';
    if (number === 0) return number.toLocaleString();
    const EXP_STEP_SIZE = 3;
    const base = Math.floor(Math.log10(Math.abs(number)));
    const siBase = (base < 0 ? Math.ceil : Math.floor)(base / EXP_STEP_SIZE);
    const prefix = siPrefixes[siBase];

    if (siBase === 0) return `${currency ? '$' : ''}${number.toLocaleString(undefined, {
        minimumFractionDigits: min,
        maximumFractionDigits: max
    })}`;
    const baseNumber = parseFloat((number / Math.pow(10, siBase * EXP_STEP_SIZE)).toFixed(2));
    return `${currency ? '$' : ''}${baseNumber.toLocaleString(undefined, {
        minimumFractionDigits: min,
        maximumFractionDigits: max
    })}${prefix}`;
}

export function getRoundedLocalNumber(number: number, currency=false, min=0, max=10) {
    if (number < 0.0001) return getLocaleNumber(number, currency, min, max);
    else if (number >= 0.0001 && number < 1) return getLocaleNumber(number, currency, min, 6);
    else return getLocaleNumber(number, currency, min, 2);
}