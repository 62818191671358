import axios from 'axios'

export class DataService {

    static async getCurrencies() {
        const data = [] as any[];

        const url = 'https://api.marketcaplogs.com/coins';

        // console.log(url);
        try {
            const response: any  = await axios.get(url);
            console.log(response);

            for(const entry of response.data) {

                data.push({
                    "name": entry.name,
                    "symbol": entry.symbol,
                    "slug": entry.slug,
                    "id": entry.cid,
                    "date_added": entry.dateAdded,
                    "max_supply": entry.maxSupply,
                    "circulating_supply": entry.circulatingSupply,
                    "total_supply": entry.totalSupply,
                    "market_cap_rank": entry.marketCapRank,
                    "market_cap_usd": entry.marketCapUsd,
                    "price_usd": entry.priceUsd,
                    "volume_usd": entry.volumeUsd,
                    "date": entry.dateLastUpdated,
                    "gid": entry.gid,
                    "image": entry.imageURL
                });
            }
        } catch (error) {
            console.error(error);
        }

        return data;
    }

    static async getDataPoints(slug: string) {
        const data = [] as any[];

        const url = 'https://api.marketcaplogs.com/coins/' + slug + '/datapoints';

        // console.log(url);
        try {
            const response: any  = await axios.get(url);
            console.log(response);

            for(const entry of response.data) {
                data.push({
                    "id": null,
                    "max_supply": entry.maxSupply,
                    "circulating_supply": entry.circulatingSupply,
                    "total_supply": entry.totalSupply,
                    "market_cap_rank": entry.marketCapRank,
                    "market_cap_usd": entry.marketCapUsd,
                    "price_usd": entry.priceUsd,
                    "volume_usd": entry.volumeUsd,
                    "date": entry.timestamp
                });
            }
        } catch (error) {
            console.error(error);
        }

        return data;
    }

}